.render-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
}

.image-container {
    cursor: pointer;
    position: relative;
    display: flex;
}

.loading-icon {
    position: relative;
    display: flex;
    align-items: center;
    color: var(--primary-bg-color);
    width: 150px;
    justify-content: center;
}

.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: zoom-out;
}

.fullscreen-image {
    max-width: 90%;
    max-height: 90%;
}

.thumbnail-image {
    width: 200px;
    height: auto;
    cursor: default;
}

.image-container .image {
    position: relative;
    display: flex;
    height: fit-content;
}

.icon-controls {
    bottom: 0;
    left: 0;
    display: flex;
    gap: 10px;
    position: absolute;
    right: 0px;
    background-color: #eff2f3e0;
    padding: 5px;
    justify-content: space-between;
}

.icon-controls .delete-icon,
.icon-controls .expand-icon {
    color: var(--low-vis-font-color);
    cursor: pointer;
}

.delete-icon:hover {
    color: var(--primary-bg-red-color);
}

.expand-icon:hover {
    color: var(--primary-font-color);
}

.upload-container {
    position: relative;
    display: flex;
    align-items: center;
}

.upload-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--primary-bg-color);
}

.upload-label:hover {
    color: var(--primary-font-color);
}

.upload-input {
    display: none;
}

.image-placeholder {
    color: var(--low-vis-font-color);
    font-size: 150px;
}

.fullscreen-image-wrapper {
    display: flex;
    justify-content: center;
    z-index: 1001;
    max-width: 90%;
    max-height: 90%;
}

.nav-left,
.nav-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    color: white;
    border: none;
    font-size: 3rem;
    z-index: 1002;
    cursor: pointer;
    user-select: none;
    padding: 0 15px;
    transition: color 0.2s;
}

.nav-left {
    left: 20px;
}

.nav-right {
    right: 20px;
}

.nav-left:hover,
.nav-right:hover {
    color: #ccc;
}

.overlay-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

@media (max-width: 800px) {
    .fullscreen-image-wrapper {
        max-width: 100%;
        max-height: 100%;
    }

    .fullscreen-image {
        max-width: 100%;
        max-height: 100%;
    }

    .nav-left,
    .nav-right {
        position: absolute;
        top: unset;
        transform: unset;
    }

    .nav-right,
    .nav-left {
        bottom: 20px;
    }
}
