.session-equipment-command {
    position: relative;
    display: block;
    width: 100%;

    .parent-button-wrapper {
        display: flex;
        height: 100%;
    }

    .command-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;

        .icon-button.event.option-button {
            flex-direction: column;
            width: 100%;
            gap: 10px;

            .device-name {
                display: flex;
                flex-grow: 1;
                width: 100%;
                justify-content: space-between;

                .icon-online {
                    color: green;
                }

                .icon-offline {
                    color: red;
                }
            }

            .device-container {
                width: 100%;
            }
        }

        .command-buttons {
            position: absolute;
            top: 0px;

            .icon-button {
                width: 100%;
            }
        }
    }
}

@media (max-width: 800px) {
    .session-equipment-command {
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: column;

        .command-container {
            position: relative;
            display: flex;
            flex-direction: column;

            .icon-button.event.option-button {
                width: unset;
                gap: 10px;
                justify-content: flex-start;
            }

            .command-buttons {
                position: relative;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                width: 100%;
            }
        }
    }
}
