.equipment-container-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;

    .equipment-container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        background-color: var(--secondary-bg-color);
        color: var(--primary-font-color);
        padding: 10px;

        header {
            padding: 10px;
        }
    }
}

.equipment-tiles {
    padding: 15px;
    color: var(--primary-font-color);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--secondary-bg-color);
    font-size: 18px;
    gap: 10px;

    &.display-column {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    &.no-padding {
        padding: 0px;
    }

    .equipment-tile {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc(25% - 10px);
        box-sizing: border-box;
        cursor: default;
        padding: 15px;
        display: flex;
        flex-direction: column;
        background-color: var(--job-tile-bg-color);
        border-radius: 5px;
        font-size: 24px;
        border: 1px solid #dddddd;
        min-width: 400px;
        gap: 10px;

        &.selected {
            border: 2px solid var(--header-primary-color);
        }

        &.add-equipment {
            justify-content: center;
            align-items: center;
        }

        textarea {
            font-size: 18px;
            display: flex;
            flex-grow: 1;
            border-width: 1px;
            padding: 5px;
            background-color: var(--primary-input-color);
            resize: vertical;
        }

        input {
            font-size: 18px;
            margin: 0;
            border-width: 1px;
            padding: 6px;
        }
    }

    .equipment-metadata > div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex-wrap: wrap;
        font-size: 16px;
        font-weight: 300;
    }

    .equipments-form {
        display: flex;
        flex-direction: column;
        font-size: 24px;
        gap: 14px;

        &.closed .toggle-form-button {
            color: var(--low-vis-font-color);
            padding: 30px;
            background-color: var(--secondary-bg-color);
            font-size: 30px;
            width: 100%;
            display: flex;
            gap: 10px;
            align-items: center;
        }

        &.closed .toggle-form-button:hover {
            color: var(--primary-font-color);
        }

        label {
            text-wrap: nowrap;
            font-size: 18px;
        }

        div div {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }

        .form {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 15px;
        }

        .form .buttons {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            gap: 5px;
        }

        button {
            font-size: 18px;
            display: flex;
            flex-grow: 1;
            justify-content: center;
            background-color: var(--recorder-btn-bg-color);
            color: var(--recorder-btn-secondary-color);
            padding: 20px;
            border: none;
            border-radius: 3px;
            width: 100%;
        }

        button:hover {
            cursor: pointer;
            background-color: var(--recorder-btn-secondary-bg-color);
        }
    }
}

.equipment-overview {
    padding: 0px;
    color: var(--primary-font-color);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--secondary-bg-color);
    font-size: 18px;
    gap: 10px;

    .equipment-tile {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc(25% - 10px);
        box-sizing: border-box;
        cursor: default;
        padding: 15px;
        display: flex;
        flex-direction: column;
        background-color: var(--job-tile-bg-color);
        border-radius: 5px;
        font-size: 24px;
        border: 1px solid #dddddd;
        min-width: 400px;
        gap: 10px;

        textarea {
            font-size: 18px;
            display: flex;
            flex-grow: 1;
            border-width: 1px;
            padding: 5px;
            background-color: var(--primary-input-color);
            resize: vertical;
        }

        input {
            font-size: 18px;
            margin: 0;
            border-width: 1px;
            padding: 6px;
        }
    }

    .equipment-tile .equipment-body .text-area-wrapper {
        display: flex;
    }

    .equipment-metadata > div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex-wrap: wrap;
        font-size: 16px;
        font-weight: 300;
        word-break: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }

    .equipment-body .equipment-metadata {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .delete-button,
    .edit-button {
        background: none;
        border: none;
        color: var(--low-vis-font-color);
        cursor: pointer;
        font-size: 17px;
        padding: 2px;
        margin: 0;
    }

    .delete-button:hover {
        color: var(--primary-bg-red-color);
    }

    .edit-button:hover {
        color: var(--primary-font-color);
    }
}

@media (max-width: 800px) {
    .equipment-overview,
    .equipment-tiles {
        padding: 0px;
        gap: 5px;
    }

    .equipment-tiles {
        margin-bottom: 30px;
    }

    .equipment-overview .equipment-tile,
    .equipment-tiles .equipment-tile {
        flex-basis: unset;
        min-width: unset;
        box-sizing: border-box;
        cursor: default;
        padding: 15px;
        display: flex;
        flex-direction: column;
        background-color: var(--job-tile-bg-color);
        border-radius: 0px;
        font-size: 24px;
        border: 1px solid #dddddd;
        width: 100%;
    }

    .equipment-container-wrapper {
        .equipment-container {
            padding: 5px;
            max-width: 100vw;
        }
    }
}
