.device-list {
    header {
        padding: 10px;
    }

    display: flex;
    flex-direction: column;
    width: 100%;
}

.dropdown-menu {
    overflow: hidden;
    transition: width 0.3s ease-in-out;
    display: flex;
    visibility: hidden;
    gap: 5px;
}

.dropdown-menu.open {
    visibility: visible; /* Only visible when open */
}

.controls {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.controls .command-button-wrapper {
    display: flex;
    margin-right: 5px;
}

.controls .command-button-wrapper > div {
    display: flex;
}

.controls .command-button-wrapper.active {
    background-color: #eff2f3;
}

/* Container for device files list */
.device-files {
    display: flex;
    padding: 10px;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    font-size: 16px;

    /* Action buttons container */
    .file-actions {
        display: flex;
        gap: 10px;
    }

    .command-button span {
        padding-left: 10px;
    }

    /* Individual file card styling */
    .device-file-card {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        width: fit-content;
        background-color: #f5f8fa;
        padding: 10px;
        gap: 5px;
        border-radius: 3px;
    }

    /* File card header with file name and action buttons */
    .device-file-header {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
    }
}

.device-container-wrapper {
    display: block;
    width: 100%;

    .device-container-flex {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 20px;

        .device-container {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            flex-direction: column;
            gap: 10px;
            background-color: var(--secondary-bg-color);
            color: var(--primary-font-color);
            padding: 30px;

            .device-manager-wrapper {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                padding: 0px;
                color: var(--primary-font-color);
                background-color: var(--job-tile-bg-color);
                font-size: 16px;
                padding: 10px;

                .device-header {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;

                    .metadata {
                        display: flex;
                        flex-wrap: wrap;

                        .input-wrapper {
                            width: 50px;
                        }

                        .title {
                            width: 400px;
                        }

                        .device-id {
                            width: 300px;
                        }
                    }
                }

                .device-tile {
                    padding: 10px;
                    border-top: 1px solid #cfcfcf;

                    &:hover {
                        cursor: pointer;
                        background-color: var(--light-background-hover);
                        border-radius: 5px 0px 0 5px;
                    }
                    .device {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .metadata {
                            display: flex;
                            flex-wrap: wrap;

                            .input-wrapper {
                                width: 50px;
                            }

                            .title {
                                width: 400px;
                            }

                            .device-id {
                                width: 300px;
                            }
                        }
                    }
                }
            }

            .device-overview {
                display: flex;
                flex-direction: column;
                background-color: var(--job-tile-bg-color);
                padding: 10px;
                font-size: 16px;

                .body {
                    .description {
                        gap: 10px;
                        display: flex;
                        flex-direction: column;
                    }
                }

                .tile-header {
                    display: flex;
                    justify-content: space-between;

                    .command-button {
                        padding: 0px 15px;
                        background-color: var(--recorder-action-btn-bg-color);
                        border: 1px solid #ddd;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        cursor: pointer;
                        // height: 52px;
                        white-space: nowrap;
                        text-overflow: ellipsis; /* Adds ellipsis (...) if text is too long */
                        width: fit-content;
                    }

                    .command-button:hover {
                        background-color: #e0e0e0;
                    }

                    .edit-button,
                    .dropdown-button {
                        background: none;
                        border: none;
                        cursor: pointer;
                        font-size: 16px;
                        padding: 10px;
                    }
                }
                /* Text styles */
                .file-label {
                    font-weight: 600;
                }

                .file-value {
                    font-weight: 300;
                }

                .no-files-message {
                    font-weight: 300;
                }

                .command-button span {
                    padding-left: 10px;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .device-container-wrapper {
        .device-container-flex {
            gap: 5px;
            .device-container {
                padding: 5px;

                .device-manager-wrapper {
                    .device-tile {
                        .device {
                            .metadata {
                                .input-wrapper {
                                    width: 100%;
                                }

                                .title {
                                    width: 100%;
                                }

                                .device-id {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .device-header {
                        .metadata {
                            .input-wrapper {
                                width: 100%;
                            }

                            .title {
                                width: 100%;
                            }

                            .device-id {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .controls .command-button-wrapper > div {
        flex-direction: column;
        width: fit-content;
    }
}
