#header {
    background-color: var(--header-primary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    z-index: 10;
}

#header .title {
    padding-left: 10px;
}

#logout-button {
    background-color: transparent;
    box-shadow: none;
    border: none;
    color: white;
    font-size: 24px;
    border-radius: 2px;
    padding: 5px;
}

#header .right-hand-components .logout-button-wrapper {
    display: flex;
    margin-right: 10px;
}

#logout-button:hover {
    background-color: var(--header-secondary-color);
    cursor: pointer;
}

#header .right-hand-components {
    display: flex;
}

#header .group-id-select {
    margin-right: 15px;
}

#header .group-id-select select {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 18px;
    border-radius: 3px;
    padding: 5px;
}

#header .group-id-select option {
    background-color: var(--primary-bg-color);
    border: none;
    color: black;
}

#header .group-id-select select:hover {
    cursor: pointer;
    outline: 1px solid white;
}

#header .group-id-select select:focus-visible {
    outline: none;
}
