.sessions-container-wrapper {
    display: block;
    width: 100%;

    .sessions-container-flex {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: var(--secondary-bg-color);

        .sessions-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            color: var(--primary-font-color);
            padding: 30px;

            .session-tile {
                padding: 0px;
            }

            header {
                padding: 10px;
            }

            .sessions-list {
                background-color: var(--job-tile-bg-color);
                padding: 10px;
                border-radius: 3px;

                .recorded-sessions {
                    .sessions-header {
                        display: flex;
                        justify-content: space-between;
                        font-weight: 500;

                        .primary {
                            width: 200px;
                        }
                        .start-time {
                            width: 180px;
                        }
                        .status {
                            width: 109px;
                        }
                    }
                }
            }

            .recorded-sessions {
                .session-entry {
                    display: flex;
                    padding: 10px 0;
                    border-top: 1px solid #cfcfcf;
                    font-size: 16px;
                    font-weight: 300;
                    position: relative;

                    .metadata {
                        display: flex;
                        flex-grow: 1;
                        justify-content: space-between;
                        gap: 40px;
                        flex-direction: row;
                        padding: 5px;

                        &:hover {
                            cursor: pointer;
                            background-color: var(--light-background-hover);
                            border-radius: 5px 0px 0 5px;
                        }

                        .primary {
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                            width: 200px;
                        }

                        .start-time {
                            width: 180px;
                        }

                        .status {
                            width: 100px;
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }

                        .context-menu-icon {
                            width: 10px;
                            height: 100%;
                            border-radius: 4px;
                            padding: 2px;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            z-index: 11;

                            &:hover {
                                background-color: var(--primary-bg-color);
                            }
                        }
                    }

                    .context-menu {
                        position: absolute;
                        top: 100%;
                        right: 0;
                        background-color: var(--job-tile-bg-color);
                        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                        border-radius: 5px;
                        padding: 5px;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        z-index: 15;

                        button {
                            background: none;
                            border: none;
                            padding: 5px 10px;
                            cursor: pointer;
                            text-align: left;
                            width: 100%;

                            &:hover {
                                background-color: var(--light-background-hover);
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 800px) {
    .sessions-container-wrapper {
        .sessions-container-flex {
            .sessions-container {
                padding: 5px;
                .recorded-sessions {
                    .session-entry {
                        .metadata {
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;

                            .status {
                                flex-grow: 1;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
