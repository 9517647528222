.equipment-devices {
    .device-overview {
        padding: 1rem;
    }

    .device-tile {
        border: 1px solid #ccc;
        margin-bottom: 1rem;
        padding: 1rem;
    }

    .device-body {
        display: flex;
        flex-direction: column;
    }

    .device-metadata {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-grow: 1;

        .title {
            font-weight: 600;
        }

        .devices {
            display: flex;
            gap: 20px;
            flex-grow: 1;
            position: relative;
            flex-wrap: wrap;
        }
    }
}

.toggle-button {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;

    &:hover {
        background-color: #0056b3;
    }
}

.assigned-device-rectangle {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    border-radius: 4px;
    position: relative;

    .device-icon {
        margin-right: 8px;
        color: #999;
    }

    .device-text {
        display: flex;
        flex-direction: column;
    }

    .device-name {
        font-weight: 600;
    }

    .device-description {
        font-size: 0.9rem;
        color: #666;
    }

    &.new-device:hover {
        background-color: #e8e8e8;
        cursor: pointer;
    }

    .context-menu-icon {
        width: 10px;
        height: 100%;
        border-radius: 4px;
        padding: 2px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        color: #999;

        &:hover {
            background-color: var(--primary-bg-color);
            color: #000;
        }
    }

    .context-menu {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: var(--job-tile-bg-color);
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        z-index: 15;

        button {
            background: none;
            border: none;
            padding: 5px 10px;
            cursor: pointer;
            text-align: left;
            width: 100%;

            &:hover {
                background-color: var(--light-background-hover);
            }
        }
    }

    .add-device-dropdown {
        margin-bottom: 1rem;

        label {
            margin-right: 8px;
            font-weight: 600;
        }

        select {
            padding: 4px 6px;
            border-radius: 4px;
            border: 1px solid #ccc;
        }
    }

    .slide-open {
        display: block;
        position: relative;
        transition: max-height 0.3s ease;

        & > div {
            padding: 20px;
            background-color: #eff2f3;
        }

        .device-list {
            display: flex;
            flex-direction: column;
            gap: 5px;
            flex-grow: 1;
            padding: 0px;
            background-color: #fdfdfd;
            position: absolute;
            width: 300px;
            padding: 5px;

            .assigned-device-rectangle {
                margin-bottom: 0;
            }

            .device-item {
                background-color: #f9f9f9;
                padding: 0.5rem;
                border: 1px solid #ddd;
                border-radius: 4px;
                cursor: pointer;

                &:hover {
                    background-color: #eee;
                }
            }
        }
    }
}
