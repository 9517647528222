/* Default: Biased toward the top */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Pushes content toward the top */
    height: 100vh;
    padding-top: 20vh; /* Adjusts spacing from the top */
    background-color: #f8f9fa;
}

.loading-content {
    text-align: center;
}

.tractor-image {
    width: 600px;
    max-width: 90%;
    height: auto;
    animation: fadeInOut 2s infinite ease-in-out;
}

.loading-text {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #555;
}

/* Fade In and Out Animation */
@keyframes fadeInOut {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}
