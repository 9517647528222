.option-selections {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
}

.option-selections .option-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    align-items: stretch;
}

.option-selections .option-buttons .option-button {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(33.3333% - 10px - 30px); /* allow space for gap and icon-button padding */
}

.option-selections .option-buttons .option-button.highlight {
    background-color: var(--recorder-btn-option-bg-color-highlight);
    box-shadow: none;
}

.option-selections .divider {
    width: 100%;
}

.option-selections .divider span {
    width: 10%;
    height: 3px;
    background-color: #d2d5d6;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
}
