.field-recorder-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-start;
}

.field-recorder {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    flex: auto;
    color: var(--primary-font-color);
    background-color: var(--secondary-bg-color);
}

.recording-icons {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    top: 0;

    .primary {
        display: flex;
        flex-direction: row;
    }

    &.actions {
        .icon-button {
            display: flex;
            flex-direction: row;
            color: var(--primary-font-color);
            background-color: #fdfdfd;
            border: 1px solid #ddd;
            border-radius: 2px;

            &.active {
                background-color: var(--recorder-btn-option-bg-color-highlight);
            }

            &:hover {
                background-color: var(--recorder-btn-action-color-hover);
            }
        }
    }
}

.record-button-group {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 30px;
    overflow: hidden;
    align-items: center;

    .start-session-controller {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        padding: 15px;
        background-color: var(--secondary-bg-color);

        .session-data-wrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;
            input {
                display: flex;
                color: var(--primary-font-color);
                font-size: 16px;
                padding: 6px;
                border-radius: 2px;
                border: 1px solid grey;
            }

            input:focus-visible {
                outline: 1px solid var(--low-vis-font-color);
            }

            .session-title {
                display: flex;

                input {
                    flex-grow: 1;
                }
            }
        }
    }
}

.record.icon-button {
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;

    &.configure {
        background-color: var(--recorder-btn-secondary-bg-color);

        &:hover {
            background-color: var(--recorder-btn-secondary-bg-color);
        }
    }
}

.record-icon.configure span {
    flex: 0 0 33%;
}

.icon-button {
    color: var(--recorder-btn-secondary-color);
    display: flex;
    padding: 13px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    min-width: 150px;
    background-color: var(--recorder-btn-bg-color);
    font-size: 16px;
    border: none;
    justify-content: center;

    &:hover {
        background-color: var(--recorder-btn-bg-color-hover);
        cursor: pointer;
    }

    &.disabled {
        background-color: var(--primary-button-disabled);

        &:hover {
            background-color: var(--recorder-btn-action-color-hover);
            cursor: default;
        }
    }

    .icon {
        font-size: 16px;
    }
}

.recorder-toolbox {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    padding: 15px;
    background-color: #ebeeef;
    z-index: 13;
    gap: 5px;
    flex-direction: column;
    width: 100%;
    padding: 0;

    .resize-handle {
        width: 100%;
        height: 6px;
        cursor: ns-resize;
        position: absolute;
        bottom: -3px;
        display: flex;
    }

    &.attached {
        box-shadow: 1px 1px 6px 1px #cbcbcb;
    }

    .toggle-session-form button {
        padding: 10px;
    }
}

.software-versions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 5px;

    .software-item {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        justify-content: center;
        color: var(--primary-font-color);
        font-size: 16px;
        padding: 5px;

        &:hover {
            background-color: var(--light-background-hover);
            border-radius: 5px 0px 0 5px;
        }

        div {
            flex-grow: 1;
            justify-content: center;
            color: var(--primary-font-color);
            font-size: 16px;
        }

        .title {
            justify-content: flex-start;
        }

        .input-wrapper {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.form-slide {
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
}

.job-selection-dropdown {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.start-session-wrapper {
    display: flex;
    justify-content: center;

    button.form-button {
        display: flex;
        flex-grow: 0;
        justify-content: center;
        background-color: var(--recorder-btn-bg-color);
        color: var(--recorder-btn-secondary-color);
        padding: 20px;
        border: none;
        border-radius: 4px;

        &:hover {
            background-color: var(--recorder-btn-secondary-bg-color);
        }
    }

    .start-session-title {
        font-size: 16px;
    }
}

.recorder-prev-session-wrapper {
    width: 100%;
}

.toolbar {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    align-items: center;
    gap: 5px;

    .tool {
        display: flex;
        align-items: center;

        .label {
            font-size: 16px;
            margin-bottom: 4px;
            margin-right: 10px;
            color: var(--primary-font-color);
        }
    }
}

.bug-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 15px;

    > div {
        display: flex;
        flex-direction: column;

        > label {
            font-size: 20px;
        }

        > input,
        > textarea {
            font-size: 18px;
            padding: 7px;
            resize: vertical;
        }
    }
}

select {
    margin: 10px 0;
    padding: 5px;
    font-size: 16px;
}

.bug-reporter-parent-wrapper {
    position: relative;
    z-index: 11;
    width: 100%;
    max-height: 100%;
    overscroll-behavior: contain;
    overflow: auto;
    pointer-events: auto;

    &.hidden {
        display: none;
    }

    &.enable-transition {
        transition: height 0.5s ease-in-out;
    }

    &.disable-overflow {
        overflow: hidden;
    }

    &.override-height {
        max-height: unset;
        height: fit-content;
    }
}

.bug-reporter-parent {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
}

.submenu {
    display: flex;

    &-title {
        font-size: 20px;
        font-weight: 600;
        margin-right: 10px;
    }

    select {
        margin-top: 0;
    }
}

.bug-reporter {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    color: var(--primary-font-color);
    position: relative;

    .title {
        font-size: 34px;
        margin-bottom: 5px;
    }

    .controls {
        display: flex;
        flex-grow: 1;
        gap: 5px;

        button {
            flex-grow: 1;
        }
    }
}

@media (max-width: 1600px) {
    .field-recorder {
        padding: 0px;
    }

    .start-session-controller {
        min-width: 0;
        width: 100%;
    }
}

@media (max-width: 800px) {
    .session-toolbar.command-button-wrapper {
        display: none;
    }

    .bug-reporter {
        padding: 0 2px;
    }

    .field-recorder {
        gap: 0;
    }

    .record-button-group {
        padding: 10px;
    }

    .icon-button {
        flex-direction: row;
        font-size: 20px;
    }

    .software-versions {
        .equipment-title {
            padding: 0;
            max-width: 100%;
        }

        & .software-item {
            display: block;
            width: 100%;
            padding: 0px;

            .title {
                display: block;
                width: 100%;
                padding: 0px;
            }

            .input-wrapper {
                display: block;
                width: 100%;

                input {
                    width: 100%;
                    box-sizing: border-box;
                }
            }
        }
    }

    .recorder-toolbox {
        gap: 0;
    }

    span {
        text-wrap: wrap;
        overflow-wrap: anywhere;
    }

    .toolbar {
        flex-wrap: wrap;
    }
}
