.option-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
    width: 100%;
    /* background-color: #ebeeef; */
    /* padding: 10px 0; */
}

.option-container::after {
    padding: 30px;
}

/* .option-container > * + * {
    border-top: 1px solid #d7d7d7;
    padding: 10px 0px;
} */

.option-container .option-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    align-content: center;
    align-items: stretch;
    align-items: center;
}

.option-container .option-buttons .option-button {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(33.3333% - 10px - 30px); /* allow space for gap and icon-button padding */
}

/* Use a more neutral color for the log action buttons. */
.option-container .option-buttons .log-actions .option-button.highlight {
    background-color: #e9e9e9;
}

.option-container .option-buttons .option-button.highlight {
    background-color: var(--recorder-btn-option-bg-color-highlight);
    box-shadow: none;
}

.option-container .option-buttons .option-button.disabled:hover {
    background-color: #fdfdfd;
    box-shadow: none;
}

.error-message {
    color: var(--error-message-color);
}

.error-message.small-font {
    font-size: 14px;
}

.option-container label {
    font-size: 20px;
}

.option-container input {
    font-size: 18px;
    padding: 7px;
}

.input-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
}

.input-container > div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.input-container > div > label {
    font-size: 16px;
}

.input-container > div > input,
.input-container > div > textarea {
    font-size: 18px;
    padding: 7px;
}

.standard-view.recording-icons.actions .option-container .log-actions {
    display: flex;
}

.standard-view.recording-icons.actions .option-container .option-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* Media Query for small screens */
@media (max-width: 800px) {
    .standard-view.recording-icons.actions .option-container .option-buttons {
        justify-content: center;
    }
    .standard-view.recording-icons.actions .option-container .log-actions {
        flex-grow: 1;
        flex-wrap: wrap;
    }

    .option-container .option-buttons {
        gap: 0;
    }
}

.required-indicator {
    color: red;
}

input:required.required-pseudo,
textarea:required.required-pseudo {
    border: 2px solid #f4cece;
}

.required-indicator.small-font {
    font-size: 14px;
}
